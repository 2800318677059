define("ember-svg-jar/inlined/forward-arrow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g opacity=\".5\" fill=\"#fff\"><path d=\"M10.737 11.127l1.168 1.385a8.304 8.304 0 01-7.782.572l-.051-.014-.261-.125-.128-.064-.182-.104-.202-.105-.095-.058a7.046 7.046 0 01-.27-.168l-.02-.006c-.2-.133-1.292-.915-1.484-.94-.555-.071-1.051.34-.611.94.195.265.608.745 1.349 1.216.893.567 2.15 1.12 2.588 1.247.62.178 1.256.296 1.899.35a9.693 9.693 0 006.216-1.597l1.264 1.49c.349.415.761.327.913-.196l1.269-4.373c.14-.526-.166-.9-.708-.839l-4.531.52c-.537.064-.692.453-.34.869zM2.27 5.939c.171 1.485 1.125 2.196 2.457 2.196 1.467 0 2.25-.864 2.25-1.926 0-1.107-.756-1.539-1.107-1.611v-.027c.252-.081.81-.405.81-1.269 0-1.089-.873-1.737-1.98-1.737-1.188 0-2.007.693-2.115 1.827l1.107.207c.09-.603.387-.954.963-.954s.9.324.9.828c0 .522-.387.774-.936.774H4.25v.954h.414c.765 0 1.179.315 1.179.927 0 .585-.414.927-1.125.927-.738 0-1.152-.459-1.269-1.368l-1.179.252zm7.801 2.196c1.395 0 2.421-.972 2.421-3.384 0-2.169-1.026-3.186-2.421-3.186-1.395 0-2.421 1.017-2.421 3.186 0 2.412 1.026 3.384 2.421 3.384zm0-1.125c-.783 0-1.206-.522-1.206-2.259 0-1.494.423-2.061 1.206-2.061.783 0 1.206.567 1.206 2.061 0 1.737-.423 2.259-1.206 2.259z\"/></g>",
    "attrs": {
      "width": "17",
      "height": "18",
      "viewBox": "0 0 17 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});