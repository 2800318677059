define("@ember-data/tracking/-private", ["exports", "@ember/-internals/metal", "@glimmer/validator"], function (_exports, _metal, _validator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.Signals = void 0;
  _exports.addToTransaction = addToTransaction;
  _exports.addTransactionCB = addTransactionCB;
  _exports.createArrayTags = createArrayTags;
  _exports.createSignal = createSignal;
  _exports.defineSignal = defineSignal;
  _exports.entangleSignal = entangleSignal;
  _exports.getSignal = getSignal;
  _exports.memoTransact = memoTransact;
  _exports.peekSignal = peekSignal;
  _exports.subscribe = subscribe;
  _exports.transact = transact;
  _exports.untracked = untracked;
  let TRANSACTION = null;
  function createTransaction() {
    const transaction = {
      cbs: new Set(),
      props: new Set(),
      sub: new Set(),
      parent: null
    };
    if (TRANSACTION) {
      transaction.parent = TRANSACTION;
    }
    TRANSACTION = transaction;
  }
  function maybeConsume(tag) {
    if (tag) {
      (0, _validator.consumeTag)(tag);
    }
  }
  function maybeDirty(tag) {
    if (tag) {
      // @ts-expect-error - we are using Ember's Tag not Glimmer's
      (0, _validator.dirtyTag)(tag);
    }
  }

  /**
   * If there is a current transaction, ensures that the relevant tag (and any
   * array computed chains symbols, if applicable) will be consumed during the
   * transaction.
   *
   * If there is no current transaction, will consume the tag(s) immediately.
   *
   * @internal
   * @param obj
   */
  function subscribe(obj) {
    if (TRANSACTION) {
      TRANSACTION.sub.add(obj);
    } else if ('tag' in obj) {
      (0, _validator.consumeTag)(obj.tag);
    } else {
      obj.ref;
    }
  }
  function updateRef(obj) {
    {
      if ('tag' in obj) {
        // @ts-expect-error - we are using Ember's Tag not Glimmer's
        (0, _validator.dirtyTag)(obj.tag);
      } else {
        obj.ref = null;
      }
    }
  }
  function flushTransaction() {
    const transaction = TRANSACTION;
    TRANSACTION = transaction.parent;
    transaction.cbs.forEach(cb => {
      cb();
    });
    transaction.props.forEach(obj => {
      // mark this mutation as part of a transaction
      obj.t = true;
      updateRef(obj);
    });
    transaction.sub.forEach(obj => {
      if ('tag' in obj) {
        (0, _validator.consumeTag)(obj.tag);
      } else {
        obj.ref;
      }
    });
  }
  async function untrack() {
    const transaction = TRANSACTION;
    TRANSACTION = transaction.parent;

    // defer writes
    await Promise.resolve();
    transaction.cbs.forEach(cb => {
      cb();
    });
    transaction.props.forEach(obj => {
      // mark this mutation as part of a transaction
      obj.t = true;
      updateRef(obj);
    });
  }
  function addToTransaction(obj) {
    if (TRANSACTION) {
      TRANSACTION.props.add(obj);
    } else {
      updateRef(obj);
    }
  }
  function addTransactionCB(method) {
    if (TRANSACTION) {
      TRANSACTION.cbs.add(method);
    } else {
      method();
    }
  }

  /**
   * Run `method` without subscribing to any tracked properties
   * controlled by EmberData.
   *
   * This should rarely be used except by libraries that really
   * know what they are doing. It is most useful for wrapping
   * certain kinds of fetch/query logic from within a `Resource`
   * `hook` or other similar pattern.
   *
   * @function untracked
   * @public
   * @static
   * @for @ember-data/tracking
   * @param method
   * @return result of invoking method
   */
  function untracked(method) {
    createTransaction();
    const ret = method();
    void untrack();
    return ret;
  }

  /**
   * Run the method, subscribing to any tracked properties
   * managed by EmberData that were accessed or written during
   * the method's execution as per-normal but while allowing
   * interleaving of reads and writes.
   *
   * This is useful when for instance you want to perform
   * a mutation based on existing state that must be read first.
   *
   * @function transact
   * @public
   * @static
   * @for @ember-data/tracking
   * @param method
   * @return result of invoking method
   */
  function transact(method) {
    createTransaction();
    const ret = method();
    flushTransaction();
    return ret;
  }

  /**
   * A helpful utility for creating a new function that
   * always runs in a transaction. E.G. this "memoizes"
   * calling `transact(fn)`, currying args as necessary.
   *
   * @method memoTransact
   * @public
   * @static
   * @for @ember-data/tracking
   * @param method
   * @return a function that will invoke method in a transaction with any provided args and return its result
   */
  function memoTransact(method) {
    return function (...args) {
      createTransaction();
      const ret = method(...args);
      flushTransaction();
      return ret;
    };
  }
  const Signals = _exports.Signals = Symbol('Signals');

  /**
   *  use to add a signal property to the prototype of something.
   *
   *  First arg is the thing to define on
   *  Second arg is the property name
   *  Third agg is the initial value of the property if any.
   *
   *  for instance
   *
   *  ```ts
   *  class Model {}
   *  defineSignal(Model.prototype, 'isLoading', false);
   *  ```
   *
   *  This is sort of like using a stage-3 decorator but works today
   *  while we are still on legacy decorators.
   *
   *  e.g. it is equivalent to
   *
   *  ```ts
   *  class Model {
   *    @signal accessor isLoading = false;
   *  }
   *  ```
   *
   *  @internal
   */
  function defineSignal(obj, key, v) {
    Object.defineProperty(obj, key, {
      enumerable: true,
      configurable: false,
      get() {
        const signals = this[Signals] = this[Signals] || new Map();
        const existing = signals.has(key);
        const _signal = entangleSignal(signals, this, key);
        if (!existing && v !== undefined) {
          _signal.lastValue = v;
        }
        return _signal.lastValue;
      },
      set(value) {
        const signals = this[Signals] = this[Signals] || new Map();
        let _signal = signals.get(key);
        if (!_signal) {
          _signal = createSignal(this, key);
          signals.set(key, _signal);
        }
        if (_signal.lastValue !== value) {
          _signal.lastValue = value;
          addToTransaction(_signal);
        }
      }
    });
  }
  function createArrayTags(obj, signal) {}

  /**
   * Create a signal for the key/object pairing.
   *
   * @internal
   * @param obj Object we're creating the signal on
   * @param key Key to create the signal for
   * @return the signal
   */
  function createSignal(obj, key) {
    const _signal = {
      key,
      tag: (0, _metal.tagForProperty)(obj, key),
      t: false,
      shouldReset: false,
      '[]': null,
      '@length': null,
      lastValue: undefined
    };
    return _signal;
  }

  /**
   * Create a signal for the key/object pairing and subscribes to the signal.
   *
   * Use when you need to ensure a signal exists and is subscribed to.
   *
   * @internal
   * @param signals Map of signals
   * @param obj Object we're creating the signal on
   * @param key Key to create the signal for
   * @return the signal
   */
  function entangleSignal(signals, obj, key) {
    let _signal = signals.get(key);
    if (!_signal) {
      _signal = createSignal(obj, key);
      signals.set(key, _signal);
    }
    subscribe(_signal);
    return _signal;
  }
  function getSignal(obj, key, initialState) {
    let signals = obj[Signals];
    if (!signals) {
      signals = new Map();
      obj[Signals] = signals;
    }
    let _signal = signals.get(key);
    if (!_signal) {
      _signal = createSignal(obj, key);
      _signal.shouldReset = initialState;
      signals.set(key, _signal);
    }
    return _signal;
  }
  function peekSignal(obj, key) {
    const signals = obj[Signals];
    if (signals) {
      return signals.get(key);
    }
  }
});