define("ember-svg-jar/inlined/play-icon-effects", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g class=\"play-icon-effects\" fill=\"none\" fill-rule=\"evenodd\"><g class=\"fx\"><circle class=\"outer-circle-fx\" cx=\"50\" cy=\"50\" r=\"50\"/><circle class=\"inner-circle-fx\" cx=\"50\" cy=\"50\" r=\"50\"/></g><path class=\"outer-circle\" d=\"M50 97c25.957 0 47-21.043 47-47S75.957 3 50 3 3 24.043 3 50s21.043 47 47 47h0z\" stroke=\"#FFF\"/><path d=\"M50 93c23.748 0 43-19.252 43-43S73.748 7 50 7 7 26.252 7 50s19.252 43 43 43h0z\" class=\"inner-circle\" stroke=\"#000\"/></g>",
    "attrs": {
      "width": "100",
      "height": "100",
      "viewBox": "0 0 100 100",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});