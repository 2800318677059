define("ember-svg-jar/inlined/play-regular", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M34.3 2.3l139.3 76.2c4.4 2.4 7 6.7 7 11.6 0 4.9-2.6 9.2-7 11.6L34.3 177.9c-2.2 1.1-4.5 1.7-6.8 1.7-2.5 0-5-.7-7.1-1.9-4.1-2.4-6.6-6.7-6.6-11.3V13.8c0-4.7 2.4-8.9 6.6-11.3 4.2-2.5 9.5-2.6 13.9-.2zm-6.8 10.2c-.5 0-.9.2-1 .3-.4.3-.7.6-.7 1v152.5c0 .4.2.8.7 1 .2.1 1.1.5 2 0l139.3-76.2c.5-.3.7-.6.7-1.1 0-.4-.2-.8-.7-1L28.5 12.8c-.4-.2-.7-.3-1-.3z\" fill=\"#000\" fill-rule=\"nonzero\"/>",
    "attrs": {
      "width": "180",
      "height": "180",
      "viewBox": "0 0 180 180",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});