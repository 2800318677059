define("@ember-data/model/util-3DHZJC9h", ["exports", "@ember/debug", "@ember/string"], function (_exports, _debug, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.c = computedMacroWithOptionalParams;
  _exports.n = normalizeModelName;
  function isElementDescriptor(args) {
    const [maybeTarget, maybeKey, maybeDesc] = args;
    return (
      // Ensure we have the right number of args
      args.length === 3 && (
      // Make sure the target is a class or object (prototype)
      typeof maybeTarget === 'function' || typeof maybeTarget === 'object' && maybeTarget !== null) &&
      // Make sure the key is a string
      typeof maybeKey === 'string' && (
      // Make sure the descriptor is the right shape
      typeof maybeDesc === 'object' && maybeDesc !== null && 'enumerable' in maybeDesc && 'configurable' in maybeDesc ||
      // TS compatibility
      maybeDesc === undefined)
    );
  }
  function computedMacroWithOptionalParams(fn) {
    return (...maybeDesc) => isElementDescriptor(maybeDesc) ? fn()(...maybeDesc) : fn(...maybeDesc);
  }
  function normalizeModelName(type) {
    return type;
  }
});