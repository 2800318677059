define("ember-svg-jar/inlined/chevron-up", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M13.148 10.398l-1.297 1.289q-.148.148-.352.148t-.352-.148L6.999 7.539l-4.148 4.148q-.148.148-.352.148t-.352-.148L.85 10.398q-.148-.148-.148-.355t.148-.355l5.797-5.789q.148-.148.352-.148t.352.148l5.797 5.789q.148.148.148.355t-.148.355z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "14",
      "height": "14",
      "viewBox": "0 0 14 14"
    }
  };
});