define("ember-svg-jar/inlined/fastforward-outline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"#000\" fill-rule=\"nonzero\"><path d=\"M78.4.7c2.3 0 4.6.6 6.7 1.7l139.3 76.2c4.4 2.4 7 6.7 7 11.6 0 4.9-2.6 9.2-7 11.6L85.1 178c-4.3 2.4-9.6 2.3-13.9-.2-4.1-2.4-6.6-6.7-6.6-11.3V14c0-4.7 2.4-8.9 6.6-11.3 2.3-1.3 4.7-2 7.2-2zm0 167.1c.3 0 .6-.1 1-.3l139.3-76.2c.5-.3.7-.6.7-1s-.2-.8-.7-1.1L79.4 13c-.9-.5-1.8-.1-2 0-.4.3-.7.6-.7 1v152.5c0 .4.2.8.7 1 .1.1.5.3 1 .3zM14.5 1h19.1c7.6 0 13.9 6.2 13.9 13.9v150.7c0 7.6-6.2 13.9-13.9 13.9H14.5c-7.6 0-13.9-6.2-13.9-13.9V14.9C.6 7.3 6.9 1 14.5 1zm19.1 166.5c1 0 1.9-.8 1.9-1.9V14.9c0-1-.8-1.9-1.9-1.9H14.5c-1 0-1.9.8-1.9 1.9v150.7c0 1 .8 1.9 1.9 1.9h19.1z\"/></g>",
    "attrs": {
      "width": "232",
      "height": "180",
      "viewBox": "0 0 232 180",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});