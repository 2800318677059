define("ember-svg-jar/inlined/chevron-left", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M9.148 2.352L5 6.5l4.148 4.148q.148.148.148.352t-.148.352l-1.297 1.297q-.148.148-.352.148t-.352-.148L1.35 6.852q-.148-.148-.148-.352t.148-.352L7.147.351q.148-.148.352-.148t.352.148l1.297 1.297q.148.148.148.352t-.148.352z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "11",
      "height": "14",
      "viewBox": "0 0 11 14"
    }
  };
});