define("ember-svg-jar/inlined/rewind-arrow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g opacity=\".5\" fill=\"#fff\"><path d=\"M6.263 11.127l-1.168 1.385a8.304 8.304 0 007.782.572l.051-.014.261-.125.128-.064.181-.104.203-.105.095-.058a6.96 6.96 0 00.27-.168l.02-.006c.2-.133 1.292-.915 1.484-.94.555-.071 1.051.34.611.94-.195.265-.608.745-1.349 1.216-.893.567-2.15 1.12-2.588 1.247a9.869 9.869 0 01-1.899.35 9.693 9.693 0 01-6.216-1.597l-1.264 1.49c-.349.415-.761.327-.913-.196L.683 10.578c-.14-.526.166-.9.708-.839l4.531.52c.537.064.692.453.34.869zM5.854 8V3.05l-1.359.855V2.6l1.449-.9h1.071V8H5.854zm5.154.135c-1.396 0-2.422-.972-2.422-3.384 0-2.169 1.026-3.186 2.421-3.186 1.396 0 2.421 1.017 2.421 3.186 0 2.412-1.026 3.384-2.42 3.384zm0-1.125c.783 0 1.206-.522 1.206-2.259 0-1.494-.424-2.061-1.207-2.061-.782 0-1.206.567-1.206 2.061 0 1.737.424 2.259 1.207 2.259z\"/></g>",
    "attrs": {
      "width": "17",
      "height": "18",
      "viewBox": "0 0 17 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});