define("ember-svg-jar/inlined/loading-outline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M479.82 670c50.97-17.06 76.92 60.45 25.94 77.51-46.43 15.6-95.79 22.1-144.68 19.22C149.63 754.24-11.81 572.54.68 361.09 13.17 149.64 194.87-11.81 406.32.68c211.45 12.49 372.89 194.19 360.4 405.64-1 17.09-3.25 35.04-6.56 51.83-10.32 52.87-90.72 37.19-80.4-15.69 2.74-13.85 4.46-26.85 5.29-40.94 9.82-166.22-117.3-309.35-283.53-319.16C235.3 72.54 92.17 199.66 82.36 365.89c-9.82 166.22 117.29 309.35 283.52 319.16 38.78 2.29 77.1-2.68 113.94-15.05z\" fill=\"currentColor\" fill-rule=\"nonzero\"/>",
    "attrs": {
      "width": "768",
      "height": "768",
      "viewBox": "0 0 768 768",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});