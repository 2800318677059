define("@algonauti/ember-cable/services/cable", ["exports", "@ember/service", "@algonauti/ember-cable/-private/consumer"], function (_exports, _service, _consumer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CableService extends _service.default {
    constructor(...args) {
      super(...args);
      this._consumers = [];
    }
    createConsumer(url) {
      let consumer = new _consumer.default(url);
      if (consumer.connect()) {
        this._consumers.push(consumer);
      }
      return consumer;
    }
    willDestroy() {
      super.willDestroy();
      this._consumers.forEach(consumer => consumer.disconnect());
    }
  }
  _exports.default = CableService;
});